import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'


function SEO({ description, lang, extraMeta, keywords, title, kind, image }) {
  return (
    <StaticQuery
      query={ graphql`
        query DefaultSEOQuery {
          site {
            siteMetadata {
              title
              description
              siteUrl
              author {
                name
              }
            }
          }
        }
      `}
      render={ data => {
        const metaDescription = description || data.site.siteMetadata.description
        let meta = [
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: kind || 'website',
          },
          {
            property: `og:site_name`,
            content: `FELD Berlin`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:creator`,
            content: data.site.siteMetadata.author.name,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          }
        ]

        if(keywords && keywords.length > 0) {
          meta = meta.concat({
            name: `keywords`,
            content: keywords.join(`, `),
          })
        }

        if(extraMeta && extraMeta.length > 0) {
          meta = meta.concat(extraMeta)
        }

        // canonical image is required
        if(image) {
          meta = meta.concat([
            {
              property: `og:image`,
              content: data.site.siteMetadata.siteUrl + image,
            },
            {
              property: `twitter:image`,
              content: data.site.siteMetadata.siteUrl + image,
            },
          ])
        }

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={ title }
            titleTemplate={ `%s` }
            meta={ meta }
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [`data science`, `machine learning`, `deep learning`],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
